// import libraries
@use "sass:meta"
@use "include-media/dist/include-media"
@use "library/breakpoints"
@use "library/variables"
@use "library/text"
@use "library/fonts"
@use "library/colors"
@use "components/spinner/spinner"
/*---------------------------------------------*/
/* App level
/*---------------------------------------------*/
@use "common/base/init"
/*---------------------------------------------*/
/* Desktop Page level components
/*---------------------------------------------*/
@use "common/common/tag"
@use "common/common/trending"
@use "common/common/mostRecent"
@use "common/common/xtra"
/*---------------------------------------------*/
/* Desktop article component level
/*---------------------------------------------*/
@use "common/story/article"
@use "common/story/audio"
@use "common/story/quote"
@use "common/common/videoPlayer"
@use "common/common/videoList"
@use "desktop/common/video"
@use "desktop/story/scribblelive"
@use "common/story/share"
@use "desktop/story/share" as share2
@use "common/common/dates"
@use "common/story/youTube"
/*---------------------------------------------*/
/* Desktop article component level - XTRA
/*---------------------------------------------*/
@use "common/story/sponsored"
@use "common/common/contribution"

@use "components/button/button"
@use "components/closeButton/closeButton"
@use "components/buttonVideoPlay/buttonVideoPlay"
@use "components/lightBoxVideo/lightBoxVideo"
@use "components/adsWrapper"
@use "components/greyLineToppedBox"
@use "components/mostRecentList"
@use "components/pageBlock"
@use "components/subHeader"
@use "components/dossierHeader"
@use "components/enhancedStorytellingHeader"
@use "components/podcastEpisode"
@use "components/podcastVisual"

@use "components/contactUsXtra"

@use "components/contentCard/contentCard"
@use "components/contentCard/--articlePageAside1/contentCard--articlePageAside1"
@use "components/contentCard/--articlePageAside2/contentCard--articlePageAside2"
@use "components/contentCardWithNewsletterFallback/contentCardWithNewsletterFallback"

@use "common/logout"

@use "../../templates/twig/_components/storyList/storyList"
@use "../../templates/twig/_components/relatedStories/relatedStories"
@use "../../templates/twig/_components/storyCard/storyCard"
@use "../../templates/twig/_components/storyCard/--related/storyCard--related"

@use "common/autoPromoBox"
@use "common/share" as share3
@use "common/trendings"
@use "common/videosList"
@use "common/mostRecent" as mostRecent3

@include meta.load-css("story/article")

body
    width: 100%
    margin: 0
    position: relative

.body--disabled
    overflow: hidden

div.main.podcast .mainStory
    @include podcastVisual.podcastVisual--podcastPage

div.main
    margin: 41px auto
    max-width: 1220px
    min-width: auto
    @media screen
        .mainContent
            display: grid
            grid-gap: 0
            width: 100%
            position: relative
            grid-template: auto auto auto 1fr / calc(100% - 300px) 300px
            grid-template-areas: "storyBody miscellaneous" "relatedStories miscellaneous" "mostRecentStories miscellaneous" "podcastFooter miscellaneous"

        .mainContent__story
            grid-area: storyBody
            margin-top: 0

            .articleBody
                .dates
                    padding-top: 8px

                    span
                        width: auto
                        display: inline-block
            @include podcastVisual.podcastVisual--podcastEpisode

        aside.articleBottom
            grid-area: relatedStories

        aside.col
            grid-area: miscellaneous


/* Switch de navigation*/
.legacy
    display: block
    padding: 0 0.5rem

body > #img1
    position: absolute



// css rules for page layout
.main
    .mainContent
        .mainStory
            .adSpotBlock--filled
                margin: 28px 0

.main:not(.main--lg)
    .mainContent
        @media screen and (max-width: breakpoints.$page-small-width)
            grid-template-columns: 782px 300px

        .mainStory
            &__errorMessageBox
                margin: 2rem 0 0

        &__relatedStories
            max-width: 46.25rem
            margin-left:  0.75rem
            margin-right: 1.875rem
            grid-area: relatedStories
            padding-top: 20px
            @include include-media.media(">small")
                margin-left: calc(100% - 740px - 30px)

        &__mostRecent, &__podcastFooter
            max-width: 46.25rem
            margin-left: 0.75rem
            margin-right: 1.875rem
            padding-top: 20px
            @include include-media.media(">small")
                margin-left: calc(100% - 740px - 30px)

            .mostRecentList > h2
                margin-bottom: 35px
        &__podcastFooter
            grid-area: podcastFooter
        &__mostRecent
            grid-area: mostRecentStories
        &__newsletterBox
            padding-left: calc(100% - 740px - 30px)
            margin-top: 0

            .nl_box
                width: 100%
                margin: 0

        &__story
            max-width: 46.25rem
            margin-left: 0.75rem
            margin-right: 1.875rem
            padding: 0
            width: initial
            @include include-media.media(">small")
                margin-left: calc(100% - 740px - 30px)



        &__related
            section
                margin-top: 0

            .adsWrapper
                .adSpotBlock__slotWrapper
                    margin-bottom: 28px

.ouvoirWrapper
    padding-top: 2rem
    padding-bottom: 1rem

@include meta.load-css("common/story/print")


div.main--lg
    max-width: initial

    .mainContent
        grid-template: auto auto 1fr / 100%
        grid-template-areas: "storyBody" "relatedStories" "mostRecentStories"

        @include include-media.media(">min")
            &__relatedStories
                width: 740px

        &__story
            margin-left: 0
            margin-right: 0

    .mainContent__related
        display: none

    .mainStory
        max-width: initial
        padding: 0

    .module--md, .module--lg
        .photoGalleryModule__visualWrapper
            .photoGalleryModule__visual
                width: 100%
                object-fit: contain !important

    .badgeCollection, .articleBody > *, .mainContent__storyHeader,  .visual :is(figcaption, .visual__title, .photoGalleryModule__navigation), .capsuleModule > *,
    .mainContent__mostRecent, .mainContent__relatedStories, .socialShare, .ouvoirWrapper, .story--keyword
        max-width: variables.$content-width-sm
        margin-left: auto !important
        margin-right: auto !important

    .module--lg
        max-width: variables.$content-width-lg

    .module--md
        max-width: variables.$content-width-md

    div.capsuleModule
        & *:first-child.visual__title
            padding-top: 1em

    div.capsuleModule.module--md, div.capsuleModule.module--lg
        padding: 70px 0 !important

    div.capsuleModule.module--md:has( > *:last-child:is(.videoModule.module--md, .videoModule.module--lg,.animationModule.module--md, .animationModule.module--lg, .photoModule.module--md.module--no-caption, .photoModule.module--lg.module--no-caption, .photoGalleryModule.module--md.module--no-caption, .photoGalleryModule.module--lg.module--no-caption)),
    div.capsuleModule.module--lg:has( > *:last-child:is(.videoModule.module--lg, .animationModule.module--lg, .photoModule.module--lg.module--no-caption, .photoGalleryModule.module--lg.module--no-caption))
        padding-bottom: 0 !important
    //on cible les premiers et derniers enfants de la capsuleModule qui ne sont pas des visuals pour ajouter du padding,
    //  si ils sont plus petits que la capsule parente

    div.capsuleModule.module--md:has( > *:first-child:is(.videoModule.module--md, .videoModule.module--lg,.animationModule.module--md, .animationModule.module--lg, .photoModule.module--md, .photoModule.module--lg, .photoGalleryModule.module--md, .photoGalleryModule.module--lg)),
    div.capsuleModule.module--lg:has( > *:first-child:is(.videoModule.module--lg, .animationModule.module--lg, .photoModule.module--lg, .photoGalleryModule.module--lg))
        padding-top: 0 !important

    div.capsuleModule.module--md > *:last-child:is(.module--md, .module--lg),
    div.capsuleModule.module--lg > *:last-child:is(.module--lg)
        &.photoModule figcaption > *:last-child
            border: none
