@use "include-media/dist/include-media"
@use "../../../../static/sass/library/breakpoints"
@use "../../../../static/sass/library/variables"
@use "../../../../static/sass/library/fonts"
@use "../../../../static/sass/library/colors"

.badge
  display: inline-block
  white-space: nowrap
  border-radius: 5px
  font-size: 0.75rem
  line-height: 1
  color: #fff
  text-transform: uppercase
  text-decoration: none
  padding: 6px
  font-family: fonts.$fontVerlag

  &:empty
    display: none

  @include include-media.media("<min")
    font-weight: bold

  &--section-XTR
    font-family: fonts.$fontRobotoSerif

  @each $name, $color in colors.$navBorder
    &--section-#{$name}
      color: #fff !important
      background-color: #{$color}
      text-decoration: none !important

  &--disabled
    background-color: #777

  &--tag
    font-weight: bold
    background-color: #ed1c23

    @include include-media.media("<min")
      font-weight: 900

  &--label
    font-weight: bold

    @each $name, $color in colors.$navBkg
      &.badge--section-#{$name}
        background-color: #{$color}

  &--videoSticker
    top: 0
    padding-left: 0
    padding-right: 80px

  &--podcast-sticker
    top: 0
    padding-left: 0
    padding-right: 80px

  &--comment-sticker
    top: 0
    display: none
    padding-left: 0
    padding-right: 62px

  &--dossier
    max-width: fit-content
    font-size: 0.75rem
    border-radius: 5px
    color: white
    text-align: center
    padding-bottom: 0
    padding-left: 0
    font-family: fonts.$fontRubrik
    font-weight: normal
    display: flex
    text-transform: lowercase
    letter-spacing: normal
    @each $name, $color in colors.$dossierTabs
      &-#{$name}
        background: #{$color}
    @each $name, $color in colors.$dossierBadge
      &-#{$name}::before
        background: #{$color}


    &:before
      text-transform: uppercase
      font-weight: bold
      font-family: fonts.$fontVerlag
      content: attr(badge-label)
      z-index: 1
      padding: 6px
      margin-top: -6px
      border-radius: 5px
      margin-right: 6px

    &--videoSticker
      top: 0
      padding-right: 80px

    &--podcast-sticker
      top: 0
      padding-right: 80px

  &--sponsored
    &:before
      content: "Série" !important
      font-family: fonts.$fontRobotoSerif
      padding-bottom: 6px
      padding-top: 6px
      font-size: 12px
      font-weight: 600
    &-lg
      &:before
        content: "Série commanditée" !important
        font-family: fonts.$fontRobotoSerif
        padding-bottom: 6px
        padding-top: 6px
        font-size: 12px
        font-weight: 600
