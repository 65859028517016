
@mixin comment-sticker

    .commentSticker
        display: none
        position: absolute
        top: 0
        left: 0
        margin-top: 0.5rem
        margin-left: Min(calc(3.2%), 0.5rem)
        border-radius: 12px
        background-color: rgba(0, 0, 0, 0.6)
        width: 50px
        height: 1.5rem
        z-index: 2

        &::before
            position: absolute
            content: url("../../img/icon-comment.svg")
            filter: invert(1)
            top: 52%
            left: 28%
            transform: translate(-50%, -50%) scale(.7)

        &__number
            font-size: 14px
            line-height: 15px
            font-family: Rubrik, Arial, sans-serif
            font-weight: 300
            display: block
            position: relative
            color: white
            top: 4px
            left: 55%

    .commentSticker__badge
        margin: 0

@mixin comment-sticker-small
    .commentSticker
        top: calc(100% - (1.65rem))
        left: max(0px, calc(50% - (50px / 2)))
        margin-top: 0
        &::after
            transform: translate(-38%, -50%)

        &__number
            font-size: 12px
            font-family: Rubrik, Arial, sans-serif
            font-weight: 300
            left: 52%

%comment-sticker
    @include comment-sticker

%comment-sticker-small
    @include comment-sticker-small
