@use "../library/breakpoints"
@use "../library/colors"
@use "include-media/dist/include-media"
@use "../library/fonts"

.subHeader
    position: relative
    font-family: fonts.$fontVerlag
    font-weight: 900
    text-transform: uppercase
    border-top: 4px solid #777
    font-size: 24px
    padding: 28px 0 28px 15px
    margin-top: 40px
    margin-bottom: -20px

    @include include-media.media(">=min")
        font-size: 20px
        padding: 30px 0
        margin-top: 60px

    @include include-media.media(">=full")
        font-size: 1.75rem
        padding: 36px 0
        margin-top: 80px

    @each $name, $color in colors.$navBorder
        &--section-#{$name}
            border-color: #{$color}
            color: #{$color}
            a
                &::after
                    border-color: #{$color}

    &--noBorder
        border: none

    &--section-PPF
        color: #232325
        a
            &::after
                border-color: #232325

    &--section-XTR
        color: #353535

        @include include-media.media(">=min")
            margin-top: 0

        .subHeader__title
            font-family: fonts.$fontRobotoSerif
            font-size: 1.5rem
            font-weight: 700

            @include include-media.media(">=min")
                font-size: 1.5rem
                font-weight: 600

            @include include-media.media(">=full")
                font-size: 1.75rem
                font-weight: 600

    &--sidebar-PASMANQUER
        border-top: 4px solid black

    &--storyGroup
        padding: 20px
        font-size: 1.85rem
        margin: 50px 0 20px 10px
        width: 95%
        border-top: 4px solid #777

        @include include-media.media(">=min")
            margin: 0 0 0 0
            width: 100%


    &--type-trending
        margin-bottom: 0
        padding-bottom: 0
        @include include-media.media(">=min")
            margin-bottom: -46px
            padding-bottom: 30px
        @include include-media.media(">=full")
            padding-bottom: 36px

    &--type-essential,
    &--type-trending,
    &--type-video
        color: #232325
        border-top-color: #232325
        font-family: fonts.$fontRubrik

    &--type-chronicle
        font-family: fonts.$fontVerlag

    &--type-purchasing-power
        border-top-color: #000
        font-family: fonts.$fontVerlag

    &__title
        display: inline-block

    &__link
        margin-right: 0
        padding-right: 0

        a
            color: inherit
            text-decoration: none

            &::after
                content: ""
                position: absolute
                top: calc(50% - 10px)
                right: 20px
                width: 15px
                height: 15px
                border-top-width: 3px
                border-right-width: 3px
                border-top-style: solid
                border-right-style: solid
                transform: rotateZ(45deg)
                @include include-media.media(">=min")
                    right: 16px
                @include include-media.media(">=full")
                    right: 30px
        &__link-discoverXTR
            a
                &::after
                    top: calc(50% - 6px)

        &--storyGroup
            a
                color: inherit
                text-decoration: none
                &::after
                    content: ""
                    position: absolute
                    top: calc(50% - 10px)
                    right: 30px
                    width: 15px
                    height: 15px
                    border-top-width: 3px
                    border-right-width: 3px
                    border-top-style: solid
                    border-right-style: solid
                    transform: rotateZ(45deg)
                    @include include-media.media(">=min")
                        right: 16px
                    @include include-media.media(">=full")
                        right: 17px


    &__subTitle
        font-family: fonts.$fontRubrik
        font-size: 0.875rem
        font-weight: 400
        line-height: 24px
        cursor: pointer
        vertical-align: bottom
        text-transform: none
        margin-left: 20px

        @include include-media.media(">=min")
            line-height: 22px
            font-size: 0.75rem

        @include include-media.media(">=full")
            line-height: 28px
            font-size: 0.875rem

        &--section-XTR
            color: #686868
            font-size: 0.75rem
            font-weight: 400
            padding: 1em 0 // padding to increase click area on mobile

            @include include-media.media(">=min")
                font-size: 0.75rem
                font-weight: 300

            @include include-media.media(">=full")
                font-size: 0.875rem
                font-weight: 300

            &--storyGroup
                margin-left: 4px
                font-size: 13px
                color: #686868
                vertical-align: middle

    &__subTitleDiscoverXTR
        display: none
        font-family: fonts.$fontRobotoSerif
        font-weight: 300

        @include include-media.media(">=min")
            display: block
            font-size: 0.875rem
            float: right
            padding: 0 6% 0 0

        @include include-media.media(">=full")
            font-size: 1.125rem
