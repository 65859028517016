@use "include-media/dist/include-media"
@use "../../library/variables"

=clearfix
    clear: both
    content: " "
    visibility: hidden
    display: block
    float: none
    height: 0

=resetListStyle
    padding: 0 
    margin: 0
    list-style: none
    border: 0
    font-size: 1rem
    vertical-align: baseline
=transitionGeneral
    transition: all 150ms linear    

=cssEllipsis
    text-overflow: ellipsis    
    overflow: hidden
    white-space: nowrap
    word-wrap: normal

*
    box-sizing: border-box
    -moz-box-sizing: border-box
    -webkit-tap-highlight-color: transparent
    outline: none

html
    font-size: 16px
    font-family: "Verlag", Arial, "Helvetica Neue", Helvetica, sans-serif
body
    background-color: #f9f9f8
sub,
sup
    line-height: 0.5em
    font-size: 0.75em
a
    word-break: break-word
figure, figcaption
    padding: 0
    margin: 0
.wrapCenter
    text-align: center
    padding: 0
    margin: 0
    line-height: 0
    font-size: 0
.webparts
    padding: 0
    margin: 0
.youtubeModule
    padding: 0.5em 0 1.5em 0
.facebookModule,
.instagramModule,
.twitterModule
    padding: variables.$module-spacing 0 0 0
    max-width: 500px
    @include include-media.media("<=959px")
        overflow-x: hidden
        margin: 0 auto
.googleMap
    width: 100%
    height: 500px
    border: 0
    margin: 0 0 1rem 0
    @include include-media.media("<=800px")
        height: calc(100vh - 80px)

#NewsletterLaMatinale,
#NewsletterRondelleLibre,
#newsletterRegisterBox,
#NewsletterLeCourrierElectoral
    margin-bottom: 3rem

.newsletterRegisterBox
    margin-bottom: 3rem

.text-insecable
    text-wrap: none
    white-space: nowrap

.clear
    clear: both

.display-none
    display: none !important
